<template>
  <div class="full-width full-height">
    <q-toolbar style="background-color: #554b85">
      <q-avatar rounded size="80px" style="margin-bottom: 10px">
        <img :src="logo" />
      </q-avatar>
      <q-space />

      <q-btn
        v-if="tab === 'mapa'"
        style="background-color: #7b854b"
        push
        @click="recuperarLocalizacion()"
        class="text-white"
      >
        <div class="row items-center">
          <q-icon left name="travel_explore" />
          <div class="text-center text-white">Localizar<br />Zona</div>
        </div>
      </q-btn>
    </q-toolbar>
    <div class="col-12 col-md-8">
      <div class="row">
        <div class="col-12">
          <q-tabs v-model="tab" class="text-teal" style="margin-top: 10px">
            <q-tab name="mapa" icon="travel_explore" label="Mapa" />
            <!-- <q-tab name="pront" icon="download" label="Prontuario" /> -->
          </q-tabs>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-8" v-if="tab === 'pront'">
      <div class="row">
        <div class="col-12">
          <q-card class="q-mx-md q-mt-md" style="color: #554b85">
            <q-card-section class="bg-primary text-white">
              Prontuario de Información Electoral Estatal
            </q-card-section>
            <q-card-section>
              <div>Seleccionar municipio</div>
              <q-select
                v-model="selector"
                use-input
                hide-selected
                fill-input
                input-debounce="0"
                :options="municipios"
                @filter="filterFn"
                style="width: 100%; padding-bottom: 32px,z-index: 1"
                @update:model-value="mostrarPdf($event)"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey"> Sin resultados </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </q-card-section>
            <q-card-section>
              <vue-pdf-app
                :config="{
                  sidebar: false,
                  secondaryToolbar: false,
                  toolbar: {
                    toolbarViewerLeft: false,
                    toolbarViewerRight: {
                      presentationMode: false,
                      openFile: false,
                      print: false,
                      download: true,
                      viewBookmark: false,
                    },
                  },
                }"
                style="height: 100vh"
                :pdf="pdf"
              ></vue-pdf-app>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-8" v-show="tab === 'mapa'">
      <div class="row">
        <div class="col-12">
          <q-card class="q-mx-md q-mt-md" style="color: #554b85">
            <q-card-section class="bg-primary text-white">
              <div class="text-h6">
                {{ tipoEleccion ? tipoEleccion : "Localización" }}
              </div>
            </q-card-section>
            <q-card-section>
              <div id="map" />
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-8" v-if="tab === 'mapa'">
      <div class="row">
        <div class="col-12">
          <q-card class="q-mx-md q-mt-md">
            <q-card-section class="bg-primary text-white">
              <div class="text-h6">Buscar datos por sección</div>
            </q-card-section>
            <q-card-section>
              <q-input
                rounded
                outlined
                v-model="seccion"
                mask="#####"
                label="Número de sección"
                @seeked="borrarDatos()"
              >
                <template v-slot:append>
                  <q-btn
                    flat
                    round
                    color="primary"
                    icon="search"
                    @click="buscarPorSeccion(true)"
                  />
                </template>
              </q-input>
            </q-card-section>
          </q-card>
        </div>
      </div>
      <div class="row" v-if="datosPorSeccion">
        <div v-if="typeof datosPorSeccion === 'string'" class="col-12">
          <q-card class="q-mx-md q-mt-xs">
            <q-card-section
              style="font-size: 30px"
              class="bg-primary text-white text-center"
            >
              Datos no encontrados
            </q-card-section>
          </q-card>
        </div>
        <div v-if="typeof datosPorSeccion !== 'string'" class="col-12">
          <q-card class="q-mx-md q-mt-xs">
            <q-card-section class="bg-primary text-white">
              Datos encontrados
            </q-card-section>
            <q-card-section>
              <q-list bordered padding>
                <q-item>
                  <q-item-section>
                    <q-item-label overline
                      ><strong>Sección:</strong> {{ seccion }}</q-item-label
                    >
                    <q-item-label overline
                      ><strong>Municipio:</strong>
                      {{
                        datosPorSeccion.datos_col4["NOMBRE\nMUNICIPIO2"].length > 0
                          ? datosPorSeccion.datos_col4["NOMBRE\nMUNICIPIO2"]
                              .split(" ")
                              .map((e) =>
                                e
                                  .toLowerCase()
                                  .split("")
                                  .map((ee, i) => {
                                    if (i == 0) return ee.toUpperCase();
                                    return ee;
                                  })
                                  .join("")
                              )
                              .join(" ")
                              .replace("�", "ñ")
                          : "No encontrado"
                      }}</q-item-label
                    >
                    <q-item-label overline
                      ><strong>Colonias:</strong>
                      <ul>
                        <li v-for="(colonia, index) in datosPorSeccion.col" :key="index">
                          {{
                            (colonia["TIPODECOL."] + " " + colonia["NOMBREDELACOL."])
                              .split(" ")
                              .map((e) =>
                                e
                                  .toLowerCase()
                                  .split("")
                                  .map((ee, i) => {
                                    if (i == 0) return ee.toUpperCase();
                                    return ee;
                                  })
                                  .join("")
                              )
                              .join(" ")
                          }}
                        </li>
                      </ul>
                    </q-item-label>
                    <q-item-label overline>
                      <strong>Manzanas:</strong>
                      {{
                        datosPorSeccion.manzanas
                          ? datosPorSeccion.manzanas.MANZANAS
                          : "---"
                      }}
                    </q-item-label>
                    <q-item-label overline>
                      <strong>Grado prom. escolaridad: </strong
                      >{{
                        datosPorSeccion.inegi ? datosPorSeccion.inegi.GRAPROES : "---"
                      }}
                    </q-item-label>
                    <!-- <q-item-label overline>
                    <strong>Tipo: </strong>{{ datosPorSeccion.datos_col1.TIPO }}
                  </q-item-label>
                  <q-item-label overline>
                    <strong>NSE: </strong>{{ datosPorSeccion.datos_col1.NSE }}
                  </q-item-label>
                  <q-item-label overline>
                    <strong>Distrito federal 2023: </strong
                    >{{ datosPorSeccion.datos_col1.DistritoFederal }}
                  </q-item-label>
                  <q-item-label overline>
                    <strong>Distrito local 2023: </strong
                    >{{ datosPorSeccion.datos_col1.DistritoLocal }}
                  </q-item-label> -->
                  </q-item-section>
                </q-item>
                <q-separator spaced />
                <q-item v-if="false">
                  <q-item-section>
                    <q-item-label overline>
                      <strong>VOLATILES MORENA:</strong>
                      {{
                        datosPorSeccion.DATOS_IMPORTANTES.VOLATILESMORENA
                      }}</q-item-label
                    >
                    <!-- <q-item-label overline>
                        <strong>LN PROYECTADA 23:</strong>
                        {{
                          datosPorSeccion.DATOS_IMPORTANTES.LNPROYECTADA23
                        }}</q-item-label
                      > -->
                    <q-item-label overline>
                      <strong>VOTOS ESPERADOS 23:</strong>
                      {{
                        datosPorSeccion.DATOS_IMPORTANTES.VOTOSESPERADOS23
                      }}</q-item-label
                    >
                    <q-item-label overline>
                      <strong>META 23:</strong>
                      {{ datosPorSeccion.DATOS_IMPORTANTES.META23 }}</q-item-label
                    >
                    <q-item-label overline>
                      <strong>DUREZA:</strong>
                      {{ datosPorSeccion.DATOS_IMPORTANTES.DUREZA }}</q-item-label
                    >
                    <q-item-label overline>
                      <strong>NC:</strong>
                      {{ datosPorSeccion.DATOS_IMPORTANTES.NC }}</q-item-label
                    >
                    <q-item-label overline>
                      <strong>CATEG 17:</strong>
                      {{ datosPorSeccion.DATOS_IMPORTANTES.CATEG17 }}</q-item-label
                    >
                    <q-item-label overline>
                      <strong>NC 21:</strong>
                      {{ datosPorSeccion.DATOS_IMPORTANTES.NC21 }}</q-item-label
                    >
                    <q-item-label overline>
                      <strong>CATEG 21:</strong>
                      {{ datosPorSeccion.DATOS_IMPORTANTES.CATEG21 }}</q-item-label
                    >
                  </q-item-section>
                </q-item>

                <q-separator spaced />
                <q-item-label header><strong>Elecciones</strong></q-item-label>
                <q-item>
                  <q-item-section style="overflow: auto">
                    <q-markup-table style="height: 50vh">
                      <cabecera-seccion></cabecera-seccion>
                      <tbody>
                        <fila-seccion
                          v-if="datosPorSeccion.datos_col3"
                          eleccion="2022 - Revocación"
                          :datosPorSeccion="datosPorSeccion"
                          datosFila="datos_col3"
                          datosGanador="X"
                          pan="X"
                          pri="X"
                          prd="X"
                          pt="X"
                          pvem="X"
                          mc="X"
                          morena="QUESIGA"
                          na="X"
                          otros="X"
                          canNoReg="X"
                          vNulo="X"
                          totales="TOTALES"
                          listaNominal="LN-22"
                          part="PART.REVO"
                        />
                        <fila-seccion
                          v-if="datosPorSeccion.DF_2021"
                          eleccion="2021 - Diputado federal"
                          :datosPorSeccion="datosPorSeccion"
                          datosFila="DF_2021"
                          datosGanador="GDF_2021"
                          pan="DF_PAN"
                          pri="DF_PRI"
                          prd="DF_PRD"
                          pt="DF_PT"
                          pvem="DF_PVEM"
                          mc="DF_MC"
                          morena="DF_MORENA"
                          na="NA"
                          otros="OTROS"
                          canNoReg="CANDIDATO/ANOREGISTRADO/A"
                          vNulo="VOTOSNULOS"
                          totales="TOTALES"
                          listaNominal="LISTA_NOMINAL_CASILLA"
                          part="PART"
                          @localizar="verMapa($event, 'DF_', '2021 - Diputado federal')"
                        />
                        <fila-seccion
                          v-if="datosPorSeccion.DL_2021"
                          eleccion="2021 - Diputado local"
                          :datosPorSeccion="datosPorSeccion"
                          datosFila="DL_2021"
                          datosGanador="GDL_2021"
                          pan="DL_PAN"
                          pri="DL_PRI"
                          prd="DL_PRD"
                          pt="DL_PT"
                          pvem="DL_PVEM"
                          mc="DL_MC"
                          morena="DL_MORENA"
                          na="DL_NAEM"
                          otros="OTROS"
                          canNoReg="NO_REGISTRADOS"
                          vNulo="NULOS"
                          totales="TOTAL"
                          listaNominal="LISTANOMINAL"
                          part="PART"
                          @localizar="verMapa($event, 'DL_', '2021 - Diputado local')"
                        />
                        <fila-seccion
                          v-if="datosPorSeccion.AY_2021"
                          eleccion="2021 - Ayuntamiento"
                          :datosPorSeccion="datosPorSeccion"
                          datosFila="AY_2021"
                          datosGanador="GAY_2021"
                          pan="PAN_AYU"
                          pri="PRI_AYU"
                          prd="PRD_AYU"
                          pt="PT_CC"
                          pvem="PVEM_AYU"
                          mc="MC_AYU"
                          morena="MORENA_CC"
                          na="NA_CC"
                          otros="OTROS"
                          canNoReg="NO_REGISTRADOS"
                          vNulo="NULOS"
                          totales="TOTAL"
                          listaNominal="LISTANOMINAL"
                          part="PART"
                          @localizar="verMapa($event, '_AYU', '2021 - Ayuntamiento')"
                        />
                        <fila-seccion
                          v-if="datosPorSeccion.PDTE_2018"
                          eleccion="2018 - Presidente"
                          :datosPorSeccion="datosPorSeccion"
                          datosFila="PDTE_2018"
                          datosGanador="GPDTE_2018"
                          pan="PAN_PDTE"
                          pri="PRI_PDTE"
                          prd="PRD_PDTE"
                          pt="PT_PDTE"
                          pvem="PVEM_PDTE"
                          mc="MC_PDTE"
                          morena="MOREN_PDTE"
                          na="NA_PDTE"
                          otros="OTROS"
                          canNoReg="CNR"
                          vNulo="VN"
                          totales="TOTALES"
                          listaNominal="LN2018"
                          part="PART.CIUD"
                          @localizar="verMapa($event, '_PDTE', '2018 - Presidente')"
                        />
                        <fila-seccion
                          v-if="datosPorSeccion.DF_2018"
                          eleccion="2018 - Diputado federal"
                          :datosPorSeccion="datosPorSeccion"
                          datosFila="DF_2018"
                          datosGanador="GDF_2018"
                          pan="PAN_DF"
                          pri="PRI_DF"
                          prd="PRD_DF"
                          pt="PT_DF"
                          pvem="PVEM_DF"
                          mc="MC_DF"
                          morena="MORENA_DF"
                          na="NA_DF"
                          otros="OTROS"
                          canNoReg="CNR"
                          vNulo="VN"
                          totales="TOTAL"
                          listaNominal="LN"
                          part="PART."
                          @localizar="verMapa($event, '_DF', '2018 - Diputado federal')"
                        />
                        <fila-seccion
                          v-if="datosPorSeccion.DL_2018"
                          eleccion="2018 - Diputado local"
                          :datosPorSeccion="datosPorSeccion"
                          datosFila="DL_2018"
                          datosGanador="GDL_2018"
                          pan="PANDL"
                          pri="PRIDL"
                          prd="PRDDL"
                          pt="PTDL"
                          pvem="PVEMDL"
                          mc="MCDL"
                          morena="MORENADL"
                          na="NADL"
                          otros="OTROS"
                          canNoReg="CNR"
                          vNulo="NULOS"
                          totales="TOTAL_VOTOS"
                          listaNominal="LISTA_NOMINA"
                          part="PART"
                          @localizar="verMapa($event, 'DL', '2018 - Diputado local')"
                        />
                        <fila-seccion
                          v-if="datosPorSeccion.AYU_2018"
                          eleccion="2018 - Ayuntamiento"
                          :datosPorSeccion="datosPorSeccion"
                          datosFila="AYU_2018"
                          datosGanador="GAY_2018"
                          pan="PANAYU"
                          pri="PRIAYU"
                          prd="PRDAYU"
                          pt="PTAYU"
                          pvem="PVEMAYU"
                          mc="MCAYU"
                          morena="MORENAAYU"
                          na="NAAYU"
                          otros="OTROSAYU"
                          canNoReg="NUM_VOTOS_CAN_NREG"
                          vNulo="NUM_VOTOS_NULOS"
                          totales="TOTAL_VOTOS"
                          listaNominal="LISTA_NOMINAL"
                          part="PART"
                          @localizar="verMapa($event, 'AYU', '2018 - Ayuntamiento')"
                        />
                        <fila-seccion
                          v-if="datosPorSeccion.CEN_2018"
                          eleccion="2018 - Senador"
                          :datosPorSeccion="datosPorSeccion"
                          datosFila="CEN_2018"
                          datosGanador="GCE_2018"
                          pan="PAN_SEN"
                          pri="PRI_SEN"
                          prd="PRD_SEN"
                          pt="PT_SEN"
                          pvem="PVEM_SEN"
                          mc="MC_SEN"
                          morena="MORENA_SEN"
                          na="NA_SEN"
                          otros="OTROS"
                          canNoReg="CNR"
                          vNulo="VN"
                          totales="TOTAL_VOTOS_CALCULADOS"
                          listaNominal="LN"
                          part="PART"
                          @localizar="verMapa($event, '_SEN', '2018 - Senador')"
                        />
                        <!-- <fila-seccion
                        v-if="datosPorSeccion.B1_2017"
                        eleccion="2017 - Gobernador"
                        :datosPorSeccion="datosPorSeccion"
                        datosFila="B1_2017"
                        datosGanador="GB1_2017"
                        pan="PAN"
                        pri="PRI"
                        prd="PRD"
                        pt="PT"
                        pvem="PVEM"
                        mc="MC"
                        morena="MORENA"
                        na="NA"
                        otros="OTROS"
                        canNoReg="NUM_VOTOS_CAN_NREG"
                        vNulo="NUM_VOTOS_NULOS"
                        totales="TOTAL_VOTOS"
                        listaNominal="LISTA_NOMINAL"
                        part="P_VOTACIÓN"
                        @localizar="verMapa($event, '_', '2017 - Gobernador')"
                      />-->
                        <fila-seccion
                          v-if="datosPorSeccion.B1_2015"
                          eleccion="2015 - Diputado federal"
                          :datosPorSeccion="datosPorSeccion"
                          datosFila="B1_2015"
                          datosGanador="GB1_2015"
                          pan="PAN"
                          pri="PRI"
                          prd="PRD"
                          pt="PT"
                          pvem="PVEN"
                          mc="MOVIMIENTO_CIUDADANO"
                          morena="MORENA"
                          na="NUEVA_ALIANZA"
                          otros="OTROS"
                          canNoReg="X"
                          vNulo="NULOS"
                          totales="TOTAL_VOTOS"
                          listaNominal="LISTA_NOMINAL"
                          part="P_PARTICIPACION"
                          @localizar="verMapa($event, '_', '2015 - Diputado federal')"
                        />
                        <fila-seccion
                          v-if="datosPorSeccion.DL_2015"
                          eleccion="2015 - Diputado local"
                          :datosPorSeccion="datosPorSeccion"
                          datosFila="DL_2015"
                          datosGanador="GDL_2015"
                          pan="PAN"
                          pri="PRIDL"
                          prd="PRD"
                          pt="PT"
                          pvem="PVEMDL"
                          mc="MC"
                          morena="MORENA"
                          na="NA"
                          otros="OTROS"
                          canNoReg="NoRegistrados"
                          vNulo="Nulos"
                          totales="Total"
                          listaNominal="LN"
                          part="PART"
                          @localizar="verMapa($event, 'DL', '2015 - Diputado local')"
                        />
                        <fila-seccion
                          v-if="datosPorSeccion.AYU_2015"
                          eleccion="2015 - Ayuntamiento"
                          :datosPorSeccion="datosPorSeccion"
                          datosFila="AYU_2015"
                          datosGanador="GAYU_2015"
                          pan="PAN"
                          pri="PRI"
                          prd="PRD"
                          pt="PT"
                          pvem="PVEM"
                          mc="MC"
                          morena="MORENA"
                          na="NA"
                          otros="OTROS"
                          canNoReg="NOREGISTRADOS"
                          vNulo="VOTOSNULOS"
                          totales="TOTALDEVOTOS"
                          listaNominal="LN"
                          part="PART"
                          @localizar="verMapa($event, '_', '2015 - Ayuntamiento')"
                        />
                        <fila-seccion
                          v-if="datosPorSeccion.PDTE_2012"
                          eleccion="2012 - Presidente"
                          :datosPorSeccion="datosPorSeccion"
                          datosFila="PDTE_2012"
                          datosGanador="GPDTE_2012"
                          pan="2012_PAN"
                          pri="2012_PRI"
                          prd="2012_PRD"
                          pt="2012_PT"
                          pvem="2012_PVEM"
                          mc="2012_MC"
                          morena="2012_MORENA"
                          na="2012_NA"
                          otros="OTROS"
                          canNoReg="CANDIDATOSNOREGISTRADOS"
                          vNulo="NULOS"
                          totales="TOTALVOTOS"
                          listaNominal="LISTANOMINAL"
                          part="PARTICIPACION"
                          @localizar="verMapa($event, '2012_', '2012 - Presidente')"
                        />
                        <fila-seccion
                          v-if="datosPorSeccion.DF_2012"
                          eleccion="2012 - Diputado federal"
                          :datosPorSeccion="datosPorSeccion"
                          datosFila="DF_2012"
                          datosGanador="GDF_2012"
                          pan="DF_PAN"
                          pri="DF_PRI"
                          prd="DF_PRD"
                          pt="DF_PT"
                          pvem="DF_PVEM"
                          mc="DF_MC"
                          morena="DF_MORENA"
                          na="X"
                          otros="OTROS"
                          canNoReg="CNR"
                          vNulo="NULOs"
                          totales="TOTALES"
                          listaNominal="DF_LISTA_NOMINAL"
                          part="PART"
                          @localizar="verMapa($event, 'DF_', '2012 - Diputado federal')"
                        />
                        <fila-seccion
                          v-if="datosPorSeccion.DL_2012"
                          eleccion="2012 - Diputado local"
                          :datosPorSeccion="datosPorSeccion"
                          datosFila="DL_2012"
                          datosGanador="GDL_2012"
                          pan="PAN"
                          pri="PRI"
                          prd="PRD"
                          pt="PT"
                          pvem="PVEM"
                          mc="MC"
                          morena="MORENA"
                          na="NA"
                          otros="X"
                          canNoReg="NoRegistrados"
                          vNulo="Nulos"
                          totales="TOTAL"
                          listaNominal="LN"
                          part="PART"
                          @localizar="verMapa($event, '_', '2012 - Diputado local')"
                        />
                        <fila-seccion
                          v-if="datosPorSeccion.AYU_2012"
                          eleccion="2012 - Ayuntamiento"
                          :datosPorSeccion="datosPorSeccion"
                          datosFila="AYU_2012"
                          datosGanador="GAYU_2012"
                          pan="PAN"
                          pri="PRI-PVEM-NA"
                          prd="PRD"
                          pt="PT"
                          pvem="PVEM"
                          mc="MC"
                          morena="MORENA"
                          na="NA"
                          otros="X"
                          canNoReg="NoReg"
                          vNulo="Nulos"
                          totales="TOTAL"
                          listaNominal="LN"
                          part="PART"
                          @localizar="verMapa($event, '-PVEM-NA', '2012 - Ayuntamiento')"
                        />
                        <fila-seccion
                          v-if="datosPorSeccion.CEN_2012"
                          eleccion="2012 - Senador"
                          :datosPorSeccion="datosPorSeccion"
                          datosFila="CEN_2012"
                          datosGanador="GCEN_2012"
                          pan="SEN_PAN"
                          pri="SEN_PRI"
                          prd="SEN_PRD"
                          pt="SEN_PT"
                          pvem="SEN_PVEM"
                          mc="SEN_MC"
                          morena="SEN_MORENA"
                          na="X"
                          otros="OTROS"
                          canNoReg="SEN_NUM_VOTOS_CAN_NREG"
                          vNulo="SEN_NUM_VOTOS_NULOS"
                          totales="SEN_TOTAL_VOTOS"
                          listaNominal="SEN_LISTA_NOMINAL"
                          part="PART"
                          @localizar="verMapa($event, 'SEN_', '2012 - Senador')"
                        />
                        <!-- <fila-seccion
                        v-if="datosPorSeccion.DF_2011"
                        eleccion="2011 - Gobernador"
                        :datosPorSeccion="datosPorSeccion"
                        datosFila="DF_2011"
                        datosGanador="GDF_2011"
                        pan="Gob_PAN"
                        pri="Gob_PRI"
                        prd="Gob_PRD"
                        pt="PT"
                        pvem="PVEM"
                        mc="MC"
                        morena="MORENA"
                        na="NA"
                        otros="OTROS"
                        canNoReg="Gob_NoRegistrados"
                        vNulo="Gob_Nulos"
                        totales="Gob_Total"
                        listaNominal="Gob_ListaNominal"
                        part="Part_11"
                        @localizar="verMapa($event, 'Gob_', '2011 - Gobernador')"
                      /> -->
                        <fila-seccion
                          v-if="datosPorSeccion.DF_2009"
                          eleccion="2009 - Diputado Federal"
                          :datosPorSeccion="datosPorSeccion"
                          datosFila="DF_2009"
                          datosGanador="GDF_2009"
                          pan="Dip_PAN"
                          pri="Dip_PRI"
                          prd="Dip_PRD"
                          pt="Dip_PT"
                          pvem="Dip_PVEM"
                          mc="Dip_CONVERGENCIA"
                          morena="MORENA"
                          na="Dip_NUEVA_ALIANZA"
                          otros="OTROS"
                          canNoReg="Dip_NUM_VOTOS_CANDIDATOS_NO_REG"
                          vNulo="Dip_NUMERO_VOTOS_NULOS"
                          totales="Dip_TOTAL_VOTOS"
                          listaNominal="Dip_LISTA_NOMINAL_CASILLA"
                          part="PART"
                          @localizar="verMapa($event, 'Dip_', '2009 - Diputado Federal')"
                        />
                        <fila-seccion
                          v-if="datosPorSeccion.DL_2009"
                          eleccion="2009 - Diputado Local"
                          :datosPorSeccion="datosPorSeccion"
                          datosFila="DL_2009"
                          datosGanador="GDL_2009"
                          pan="PAN"
                          pri="PRI"
                          prd="PRD"
                          pt="PT"
                          pvem="PVEM"
                          mc="C"
                          morena="MOR"
                          na="NA"
                          otros="OTROS"
                          canNoReg="NoRegistrados"
                          vNulo="Nulos"
                          totales="Total"
                          listaNominal="ListaNominal"
                          part="part"
                          @localizar="verMapa($event, '_', '2009 - Diputado Local')"
                        />
                        <fila-seccion
                          v-if="datosPorSeccion.AYU_2009"
                          eleccion="2009 - Ayuntamiento"
                          :datosPorSeccion="datosPorSeccion"
                          datosFila="AYU_2009"
                          datosGanador="GAYU_2009"
                          pan="PAN"
                          pri="PRI"
                          prd="PRD"
                          pt="PT"
                          pvem="PVEM"
                          mc="C"
                          morena="morena"
                          na="NA"
                          otros="OTROS"
                          canNoReg="NoReg"
                          vNulo="Nulos"
                          totales="Total"
                          listaNominal="ListaNominal"
                          part="X"
                          @localizar="verMapa($event, '_', '2009 - Ayuntamiento')"
                        />
                        <fila-seccion
                          v-if="datosPorSeccion.PDTE_2006"
                          eleccion="2006 - Presidente"
                          :datosPorSeccion="datosPorSeccion"
                          datosFila="PDTE_2006"
                          datosGanador="GPDTE_2006"
                          pan="2006_PAN"
                          pri="2006_PRI"
                          prd="2006_PRD"
                          pt="2006_PT"
                          pvem="2006_PVEM"
                          mc="2006_MC"
                          morena="2006_MORENA"
                          na="2006_NA"
                          otros="2006_OTROS"
                          canNoReg="NO_VOTOS_CAN_NREG"
                          vNulo="NO_VOTOS_NULOS"
                          totales="TOTAL"
                          listaNominal="LISTA_NOMINAL"
                          part="PART.CIUD"
                          @localizar="verMapa($event, '2006_', '2006 - Presidente')"
                        />
                        <fila-seccion
                          v-if="datosPorSeccion.DF_2006"
                          eleccion="2006 - Diputado Federal"
                          :datosPorSeccion="datosPorSeccion"
                          datosFila="DF_2006"
                          datosGanador="GDF_2006"
                          pan="PAN"
                          pri="PRI"
                          prd="PRD"
                          pt="PT"
                          pvem="PVEM"
                          mc="CONV"
                          morena="MORENA"
                          na="NVA_ALIANZA"
                          otros="OTROS"
                          canNoReg="NUM_VOTOS_CAN_NREG"
                          vNulo="NUM_VOTOS_NULOS"
                          totales="TOTAL_VOTOS"
                          listaNominal="LISTA_NOMINAL"
                          part="PART"
                          @localizar="verMapa($event, '_', '2006 - Diputado Federal')"
                        />
                        <fila-seccion
                          v-if="datosPorSeccion.DL_2006"
                          eleccion="2006 - Diputado Local"
                          :datosPorSeccion="datosPorSeccion"
                          datosFila="DL_2006"
                          datosGanador="GDL_2006"
                          pan="PAN"
                          pri="PRI"
                          prd="PRD"
                          pt="PT"
                          pvem="PVEM"
                          mc="CONVERGENCIA"
                          morena="MORENA"
                          na="NA"
                          otros="OTROS"
                          canNoReg="CANDIDATOSNOREGISTRADOS"
                          vNulo="VOTOSNULOS"
                          totales="TOTALDELAVOTACION"
                          listaNominal="ListaNominal"
                          part="PART"
                          @localizar="verMapa($event, '_', '2006 - Diputado Local')"
                        />
                        <fila-seccion
                          v-if="datosPorSeccion.CEN_2006"
                          eleccion="2006 - Senador"
                          :datosPorSeccion="datosPorSeccion"
                          datosFila="CEN_2006"
                          datosGanador="GCEN_2006"
                          pan="PAN"
                          pri="PRI"
                          prd="PRD"
                          pt="PT"
                          pvem="PVEM"
                          mc="CONVE"
                          morena="MORENA"
                          na="NVA_ALIANZA"
                          otros="OTROS"
                          canNoReg="NUM_VOTOS_CAN_NREG"
                          vNulo="NUM_VOTOS_NULOS"
                          totales="TOTAL_VOTOS"
                          listaNominal="LISTA_NOMINAL"
                          part="PART"
                          @localizar="verMapa($event, '_', '2006 - Senador')"
                        />
                        <!--<fila-seccion
                        v-if="datosPorSeccion.DF_2005"
                        eleccion="2005 - Gobernador"
                        :datosPorSeccion="datosPorSeccion"
                        datosFila="DF_2005"
                        datosGanador="GDF_2005"
                        pan="PAN"
                        pri="PRI"
                        prd="PRD"
                        pt="PT"
                        pvem="PVEM"
                        mc="CONV"
                        morena="MORENA"
                        na="NA"
                        otros="OTROS"
                        canNoReg="CANDIDATOSNOREGISTRADOS"
                        vNulo="VOTOSNULOS"
                        totales="TOTAL"
                        listaNominal="LN2005"
                        part="part.2005"
                        @localizar="verMapa($event, '_', '2005 - Gobernador')"
                      />
                      <fila-seccion
                        v-if="datosPorSeccion.DF_2003"
                        eleccion="2003 - Diputado Federal"
                        :datosPorSeccion="datosPorSeccion"
                        datosFila="DF_2003"
                        datosGanador="GDF_2003"
                        pan="PAN"
                        pri="PRI"
                        prd="PRD"
                        pt="PT"
                        pvem="PVEM"
                        mc="CONV"
                        morena="morena"
                        na="NA"
                        otros="otros"
                        canNoReg="CNR"
                        vNulo="NULOS"
                        totales="TOTAL_VOTOS"
                        listaNominal="X"
                        part="X"
                        @localizar="verMapa($event, '_', '2003 - Diputado Federal')"
                      />
                      <fila-seccion
                        v-if="datosPorSeccion.DL_2003"
                        eleccion="2003 - Diputado Local"
                        :datosPorSeccion="datosPorSeccion"
                        datosFila="DL_2003"
                        datosGanador="GDL_2003"
                        pan="PAN"
                        pri="APT"
                        prd="PRD"
                        pt="PT"
                        pvem="PVEM"
                        mc="C"
                        morena="MORENA"
                        na="NA"
                        otros="OTROS"
                        canNoReg="NOREG"
                        vNulo="NULOS"
                        totales="TOTAL"
                        listaNominal="LISTANOMINAL"
                        part="PART"
                        @localizar="verMapa($event, '_', '2003 - Diputado Local')"
                      />
                      <fila-seccion
                        v-if="datosPorSeccion.AYU_2003"
                        eleccion="2003 - Ayuntamiento"
                        :datosPorSeccion="datosPorSeccion"
                        datosFila="AYU_2003"
                        datosGanador="GAYU_2003"
                        pan="pan"
                        pri="PRI"
                        prd="prd"
                        pt="pt"
                        pvem="PVEM"
                        mc="conv"
                        morena="MORENA"
                        na="NA"
                        otros="OTROS"
                        canNoReg="no_reg"
                        vNulo="nulos"
                        totales="TOTAL"
                        listaNominal="listanominal"
                        part="PART"
                        @localizar="verMapa($event, '_', '2003 - Ayuntamiento')"
                      />
                      <fila-seccion
                        v-if="datosPorSeccion.PDTE_2000"
                        eleccion="2000 - Presidente"
                        :datosPorSeccion="datosPorSeccion"
                        datosFila="PDTE_2000"
                        datosGanador="GPDTE_2000"
                        pan="PAN"
                        pri="PRI"
                        prd="PRD"
                        pt="PT"
                        pvem="PVEM"
                        mc="CONV"
                        morena="MORENA"
                        na="NA"
                        otros="OTROS"
                        canNoReg="NUM_VOTOS_CAN_NREG"
                        vNulo="NUM_VOTOS_NULOS"
                        totales="TOTAL_VOTOS"
                        listaNominal="LN"
                        part="partc"
                        @localizar="verMapa($event, '_', '2000 - Presidente')"
                      />
                      <fila-seccion
                        v-if="datosPorSeccion.DF_2000"
                        eleccion="2000 - Diputado Federal"
                        :datosPorSeccion="datosPorSeccion"
                        datosFila="DF_2000"
                        datosGanador="GDF_2000"
                        pan="AC"
                        pri="PRI"
                        prd="PRD"
                        pt="PT"
                        pvem="PVEM"
                        mc="CONV"
                        morena="MORENA"
                        na="NA"
                        otros="OTROS"
                        canNoReg="NUM_VOTOS_CAN_NREG"
                        vNulo="NUM_VOTOS_NULOS"
                        totales="TOTAL_VOTOS"
                        listaNominal="LN"
                        part="PARTC"
                        @localizar="verMapa($event, '_', '2000 - Diputado Federal')"
                      />
                      <fila-seccion
                        v-if="datosPorSeccion.DL_2000"
                        eleccion="2000 - Diputado Local"
                        :datosPorSeccion="datosPorSeccion"
                        datosFila="DL_2000"
                        datosGanador="GDL_2000"
                        pan="PAN"
                        pri="PRI"
                        prd="PRD"
                        pt="PT"
                        pvem="PVEM"
                        mc="CD"
                        morena="MORENA"
                        na="NA"
                        otros="OTROS"
                        canNoReg="NoReg."
                        vNulo="Nulos"
                        totales="Total"
                        listaNominal="LN"
                        part="PART"
                        @localizar="verMapa($event, '_', '2000 - Diputado Local')"
                      />
                      <fila-seccion
                        v-if="datosPorSeccion.AYU_2000"
                        eleccion="2000 - Ayuntamiento"
                        :datosPorSeccion="datosPorSeccion"
                        datosFila="AYU_2000"
                        datosGanador="GAYU_2000"
                        pan="PAN"
                        pri="PRI"
                        prd="PRD"
                        pt="PT"
                        pvem="PVEM"
                        mc="CD"
                        morena="MORENA"
                        na="NA"
                        otros="OTROS"
                        canNoReg="NoReg."
                        vNulo="Nulos"
                        totales="Total"
                        listaNominal="LN"
                        part="PART"
                        @localizar="verMapa($event, '_', '2000 - Ayuntamiento')"
                      />
                      <fila-seccion
                        v-if="datosPorSeccion.CEN_2000"
                        eleccion="2000 - Senador"
                        :datosPorSeccion="datosPorSeccion"
                        datosFila="CEN_2000"
                        datosGanador="GCEN_2000"
                        pan="AC"
                        pri="PRI"
                        prd="PRD"
                        pt="PT"
                        pvem="PVEM"
                        mc="CONV"
                        morena="MORENA"
                        na="NA"
                        otros="OTROS"
                        canNoReg="NUM_VOTOS_CAN_NREG"
                        vNulo="NUM_VOTOS_NULOS"
                        totales="TOTAL_VOTOS"
                        listaNominal="LN"
                        part="partc"
                        @localizar="verMapa($event, '_', '2000 - Senador')"
                      />
                      <fila-seccion
                        v-if="datosPorSeccion.DF_1999"
                        eleccion="1999 - Gobernador"
                        :datosPorSeccion="datosPorSeccion"
                        datosFila="DF_1999"
                        datosGanador="GDF_1999"
                        pan="PAN"
                        pri="PRI"
                        prd="PRD"
                        pt="PT"
                        pvem="PVEM"
                        mc="CONV"
                        morena="MORENA"
                        na="NA"
                        otros="OTROS"
                        canNoReg="CNR"
                        vNulo="Nulos"
                        totales="Total"
                        listaNominal="LISTA"
                        part="PART"
                        @localizar="verMapa($event, '_', '1999 - Gobernador')"
                      /> -->
                      </tbody>
                    </q-markup-table>
                  </q-item-section>
                </q-item>
                <q-separator spaced />
                <q-item-label header
                  ><strong>Graficas de resultados</strong></q-item-label
                >
                <q-item>
                  <q-item-section>
                    <apexchart
                      height="200"
                      type="line"
                      :options="options"
                      :series="seriesLineal1"
                    ></apexchart>
                  </q-item-section>
                </q-item>
                <q-separator spaced />
                <q-item-label header
                  ><strong>Lista nominal por edad 2023</strong></q-item-label
                >
                <q-item>
                  <q-item-section>
                    <q-item-label overline
                      ><strong>Total hombres:</strong>
                      {{ datosPorSeccion.datos_col4["LISTA\nHOMBRES"] }}</q-item-label
                    >
                    <q-item-label overline
                      ><strong>Total mujeres:</strong>
                      {{ datosPorSeccion.datos_col4["LISTA\nMUJERES"] }}</q-item-label
                    >
                    <q-item-label overline
                      ><strong>Lista nominal:</strong>
                      {{ datosPorSeccion.datos_col4["LISTA\nNOMINAL"] }}</q-item-label
                    >
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <apexchart
                      height="200"
                      type="bar"
                      :options="options2"
                      :series="seriesBar1"
                    ></apexchart>
                  </q-item-section>
                </q-item>
                <q-separator spaced />
                <!-- <q-item>
                    <q-item-section>
                      <apexchart
                        height="500"
                        type="bar"
                        :options="options3"
                        :series="seriesBar2"
                      ></apexchart>
                    </q-item-section>
                  </q-item>
                  <q-separator spaced /> -->

                <q-item>
                  <q-item-section style="overflow: auto" v-if="false">
                    <div class="row justify-center">
                      <div class="col-12 col-md-8 col-lg-6">
                        <q-markup-table separator="cell" flat bordered dense>
                          <thead
                            style="
                              background-color: #554b85;
                              color: white;
                              font-weight: 600;
                            "
                          >
                            <tr>
                              <th colspan="4">
                                <div>PARTICIPACIÓN POR EDADES</div>
                              </th>
                            </tr>
                            <tr>
                              <th>RANGO</th>
                              <th>PESO</th>
                              <th>PART.</th>
                              <th>VALOR ABSOLUTO</th>
                            </tr>
                          </thead>
                          <tbody style="background-color: #e0c4c4">
                            <tr v-for="(tabla, index) in tablaNueva" :key="index">
                              <td>{{ tabla.nombre }}</td>
                              <td>{{ tabla.peso.toFixed(2) }}%</td>
                              <td>{{ tabla.part }}%</td>
                              <td>
                                <div
                                  :style="{
                                    backgroundColor: '#554B85',
                                    width: tabla.peso.toFixed(2) * 5 + '%',

                                    color: 'white',
                                  }"
                                >
                                  {{ tabla.valorAbsoluto.toFixed(0) }}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </q-markup-table>
                      </div>
                    </div>
                  </q-item-section>
                </q-item>
                <q-separator spaced />
                <q-item>
                  <q-item-section>
                    <div class="row justify-center">
                      <div class="col-12 col-md-8 col-lg-6">
                        <q-table
                          v-if="false"
                          class="my-sticky-header-table"
                          :columns="columns"
                          :rows="filas"
                          dense
                          hide-bottom
                          virtual-scroll
                          :rows-per-page-options="[0]"
                          row-key="estado"
                          separator="cell"
                        >
                          <template v-slot:body="props">
                            <q-tr :props="props">
                              <q-td
                                key="estado"
                                :props="props"
                                :style="{
                                  backgroundColor: props.row.maximo ? 'green' : null,
                                  color: props.row.maximo ? 'white' : null,
                                  fontSize: '10px',
                                }"
                              >
                                {{ props.row.estado }}
                              </q-td>
                              <q-td
                                key="participacion"
                                :props="props"
                                :style="{
                                  backgroundColor: props.row.maximo ? 'green' : null,
                                  color: props.row.maximo ? 'white' : null,
                                  fontSize: '10px',
                                }"
                              >
                                {{ props.row.participacion }}
                              </q-td>
                              <q-td
                                key="porcentaje"
                                :props="props"
                                :style="{
                                  backgroundColor: props.row.maximo ? 'green' : null,
                                  color: props.row.maximo ? 'white' : null,
                                  fontSize: '10px',
                                }"
                              >
                                {{ props.row.porcentaje.toFixed(2) }}%
                              </q-td>
                            </q-tr>
                          </template>
                        </q-table>
                      </div>
                    </div>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import cabeceraSeccion from "./cabeceraSeccion.vue";
import filaSeccion from "./filaSeccion.vue";
import VuePdfApp from "vue3-pdf-app";

import axios from "axios";

import "mapbox-gl/dist/mapbox-gl.css";
import Mapbox from "mapbox-gl";
import * as turf from "@turf/turf";
import "vue3-pdf-app/dist/icons/main.css";

export default {
  name: "App",
  components: {
    cabeceraSeccion,
    filaSeccion,
    VuePdfApp,
  },
  props: {
    data: Object,
  },
  setup(props) {
    const produccion = true;

    const idUser = ref(props.data.idUser);
    const secciones = ref(props.data.secciones);

    //const cordenadas = ref([-99.53058430553963, 19.8199675577429])
    const selector = ref("Información");
    const tab = ref("mapa");
    const pdf = ref("/descargas/Info.pdf");
    const stringsMuni = [
      "Información",
      "Acatic",
      "Acatlán de Juárez",
      "Ahualulco de Mercado",
      "Amacueca",
      "Amatitán",
      "Ameca",
      "San Juanito de Escobedo",
      "Arandas",
      "El Arenal",
      "Atemajac de Brizuela",
      "Atengo",
      "Atenguillo",
      "Atotonilco el Alto",
      "Atoyac",
      "Autlán de Navarro",
      "Ayotlán",
      "Ayutla",
      "La Barca",
      "Bolaños",
      "Cabo Corrientes",
      "Casimiro Castillo",
      "Cihuatlán",
      "Zapotlán el Grande",
      "Cocula",
      "Colotlán",
      "Concepción de Buenos Aires",
      "Cuautitlán de García Barragán",
      "Cuautla",
      "Cuquío",
      "Chapala",
      "Chimaltitán",
      "Chiquilistlán",
      "Degollado",
      "Ejutla",
      "Encarnación de Díaz",
      "Etzatlán",
      "El Grullo",
      "Guachinango",
      "Guadalajara,629",
      "Hostotipaquillo",
      "Huejúcar",
      "Huejuquilla el Alto",
      "La Huerta",
      "Ixtlahuacán de los Membrillos",
      "Ixtlahuacán del Río",
      "Jalostotitlán",
      "Jamay",
      "Jesús María",
      "Jilotlán de los Dolores",
      "Jocotepec",
      "Juanacatlán",
      "Juchitlán",
      "Lagos de Moreno",
      "El Limón",
      "Magdalena",
      "Santa María del Oro",
      "La Manzanilla de la Paz",
      "Mascota",
      "Mazamitla",
      "Mexticacán",
      "Mezquitic",
      "Mixtlán",
      "Ocotlán",
      "Ojuelos de Jalisco",
      "Pihuamo",
      "Poncitlán",
      "Puerto Vallarta",
      "Villa Purificación",
      "Quitupan",
      "El Salto",
      "San Cristóbal de la Barranca",
      "San Diego de Alejandría",
      "San Juan de los Lagos",
      "San Julián",
      "San Marcos",
      "San Martín de Bolaños",
      "San Martín Hidalgo",
      "San Miguel el Alto",
      "Gómez Farías",
      "San Sebastián del Oeste",
      "Santa María de los Ángeles",
      "Sayula",
      "Tala",
      "Talpa de Allende",
      "Tamazula de Gordiano",
      "Tapalpa",
      "Tecalitlán",
      "Techaluta de Montenegro",
      "Tecolotlán",
      "Tenamaxtlán",
      "Teocaltiche",
      "Teocuitatlán de Corona",
      "Tepatitlán de Morelos",
      "Tequila",
      "Teuchitlán",
      "Tizapán el Alto",
      "Tlajomulco de Zúñiga",
      "San Pedro Tlaquepaque",
      "Tolimán",
      "Tomatlán",
      "Tonalá",
      "Tonaya",
      "Tonila",
      "Totatiche",
      "Tototlán",
      "Tuxcacuesco",
      "Tuxcueca",
      "Tuxpan",
      "Unión de San Antonio",
      "Unión de Tula",
      "Valle de Guadalupe",
      "Valle de Juárez",
      "San Gabriel",
      "Villa Corona",
      "Villa Guerrero",
      "Villa Hidalgo",
      "Cañadas de Obregón",
      "Yahualica de González Gallo",
      "Zacoalco de Torres",
      "Zapopan,491",
      "Zapotiltic",
      "Zapotitlán de Vadillo",
      "Zapotlán del Rey",
      "Zapotlanejo",
      "San Ignacio Cerro Gordo",
    ];
    const municipios = ref([stringsMuni]);
    const pagina = ref(1);
    const tablaNueva = ref([]);
    const cordenadas = ref([-103.8621261, 20.6814808]);
    const tipoEleccion = ref(null);
    let mapa = null;
    const marker = new Mapbox.Marker({
      color: "blue",
      draggable: false,
    });
    const popup = new Mapbox.Popup({ closeOnClick: false, closeButton: false });

    const recuperarLocalizacion = () => {
      geolocate.trigger();
    };

    const geolocate = new Mapbox.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
    });
    const accessToken =
      "pk.eyJ1IjoibHVuZWxtY2ptIiwiYSI6ImNsNTBid3h1MjM2dXIzamxwbHIwZmM0NHQifQ.ItZ69A6RAW1fpZ6rBAXD6Q";
    const mapStyle = "mapbox://styles/lunelmcjm/clduomjti000001ln65my29jk";
    const agregar = (secc) => {
      mapa.addLayer({
        id: "actual",
        type: "fill",
        source: "states-source",
        "source-layer": "14_JALISCO-ch190o",
        paint: {
          "fill-color": "#554B85",
          "fill-opacity": 0.6,
          "fill-outline-color": "#000",
        },
        filter: ["==", ["number", ["get", "SECCION"]], secc],
      });
    };

    onMounted(() => {
      //console.log("Montado")
      //console.log("Cordenadas",cordenadas.value)
      if (idUser.value === "0") {
        return;
      }
      Mapbox.accessToken = accessToken;
      const map = new Mapbox.Map({
        container: "map",
        style: mapStyle,
        center: cordenadas.value,
        zoom: 6,
      });

      map.on("load", () => {
        map.addSource("states-source", {
          type: "vector",
          url: "mapbox://lunelmcjm.3p5ihdcu",
        });
        map.addLayer({
          id: "states-data",
          type: "fill",
          source: "states-source",
          "source-layer": "14_JALISCO-ch190o",
          layout: {},
          paint: {
            "fill-color": "#fff",
            "fill-opacity": 0.5,
            "fill-outline-color": "#000",
          },
        });
        //console.log(secciones.value[0])
        if (secciones.value[0] !== "all") {
          const info = secciones.value
            .map((e) => e.SECCION)
            .map((e) => (!isNaN(Number(e)) ? [Number(e), "#fff"] : null))
            .filter(Boolean)
            .flat();
          //console.log(info)
          map.addLayer({
            id: "colores2",
            type: "fill",
            source: "states-source",
            "source-layer": "14_JALISCO-ch190o",
            paint: {
              "fill-color": ["match", ["get", "SECCION"], ...info, "#bbb"],
              "fill-opacity": 0.7,
              "fill-outline-color": "#000",
            },
          });
        }

        map.addControl(geolocate);
        map.on("click", "states-data", async (e) => {
          let secc = e.features[0].properties.SECCION;
          if (secc) {
            seccion.value = secc;
            let coordinatesT = e.features[0].geometry.coordinates[0];
            //console.log(e.features)
            if (coordinatesT.length === 1) {
              coordinatesT = coordinatesT[0];
            }
            const polygonT = turf.polygon([coordinatesT]);
            const center = turf.center(polygonT);
            //console.log(center)
            const bounds = new Mapbox.LngLatBounds();
            coordinatesT.forEach((coord) => {
              bounds.extend(coord);
            });

            map.easeTo({
              center: center.geometry.coordinates,
              zoom: 10,
              duration: 1000,
            });
            map.fitBounds(bounds, {
              padding: 20,
            });
            await buscarPorSeccion(false);
            popup
              .setLngLat(center.geometry.coordinates)
              .setHTML(`<div style="font-size:2em;">Sección ${seccion.value}</div>`)
              .addTo(map);
            if (!map.getLayer("actual")) {
              agregar(secc);
            } else {
              map.removeLayer("actual");
              agregar(secc);
            }
          }
          //console.log(e.features[0],e.lngLat);
        });
        geolocate.on("geolocate", (e) => {
          marker.setLngLat([e.coords.longitude, e.coords.latitude]).addTo(mapa);
        });
        //console.log("Mapa cargado")
      });
      mapa = map;
    });

    const seccion = ref(null);
    const logo = require("../assets/logoElectopedia.svg");
    const datosPorSeccion = ref(null);
    const seriesLineal1 = ref(null);
    const seriesBar1 = ref(null);
    const seriesBar2 = ref(null);
    const filas = ref(null);
    const columns = [
      { name: "estado", label: "Estado", field: "estado", align: "center" },
      {
        name: "participacion",
        label: "Participación",
        field: "participacion",
        align: "center",
      },
      { name: "porcentaje", label: "%", field: "porcentaje", align: "center" },
    ];
    const buscarPorSeccion = async (tipo) => {
      if (seccion.value === null || seccion.value === "") {
        console.log("No se ha introducido ninguna sección");
        return;
      }
      let direccion = produccion
        ? `/api/jal/secc/${seccion.value}/12345/${encodeURIComponent(idUser.value)}`
        : `http://localhost:3000/api/jal/secc/${seccion.value}/12345/${encodeURIComponent(
            idUser.value
          )}`;
      //const { data } = await axios.get(
      //  `http://localhost:3000/api/secc/${seccion.value}/12345/`
      //);
      const { data } = await axios.get(direccion);
      datosPorSeccion.value = data;
      //console.log(datosPorSeccion.value)
      if (typeof data === "string") {
        console.log("No se encontró la sección");
        return;
      }
      //console.log(data);
      if (tipo) {
        const datosMapa = mapa.querySourceFeatures("states-source", {
          sourceLayer: "14_JALISCO-ch190o",
          filter: ["==", ["number", ["get", "SECCION"]], Number(seccion.value)],
        });
        //console.log(datosMapa[0])
        //console.log(seccion.value)
        if (datosMapa.length !== 0) {
          //console.log(datosMapa[0].geometry.coordinates[0])

          let coordinatesT = datosMapa[0].geometry.coordinates[0];
          if (coordinatesT.length === 1) {
            coordinatesT = coordinatesT[0];
          }
          const polygonT = turf.polygon([coordinatesT]);
          const center = turf.center(polygonT);
          const bounds = new Mapbox.LngLatBounds();
          coordinatesT.forEach((coord) => {
            bounds.extend(coord);
          });

          //console.log(datosMapa[0].geometry.coordinates[0][0],datosMapa[0])
          let sec = datosMapa[0].properties.SECCION;
          mapa.easeTo({
            center: center.geometry.coordinates,
            zoom: 10,
            duration: 1000,
          });

          mapa.fitBounds(bounds, {
            padding: 20,
          });
          popup
            .setLngLat(center.geometry.coordinates)
            .setHTML(`<div style="font-size:2em;">Sección ${seccion.value}</div>`)
            .addTo(mapa);

          if (!mapa.getLayer("actual")) {
            agregar(sec);
          } else {
            mapa.removeLayer("actual");
            agregar(sec);
          }
        } else {
          popup.remove();
          mapa.removeLayer("actual");
          mapa.easeTo({
            center: [-99.3345428, 19.3659331],
            zoom: 7,
            duration: 1000,
          });
        }
      }
      let part = [];
      //part[0] = Number(datosPorSeccion.value.datos_col1["18A"].replace("%", ""));
      //part[1] = Number(datosPorSeccion.value.datos_col1["19A"].replace("%", ""));
      //part[2] = Number(datosPorSeccion.value.datos_col1["20-24A"].replace("%", ""));
      //part[3] = Number(datosPorSeccion.value.datos_col1["25-29A"].replace("%", ""));
      //part[4] = Number(datosPorSeccion.value.datos_col1["30-34A"].replace("%", ""));
      //part[5] = Number(datosPorSeccion.value.datos_col1["35-39A"].replace("%", ""));
      //part[6] = Number(datosPorSeccion.value.datos_col1["40-44A"].replace("%", ""));
      //part[7] = Number(datosPorSeccion.value.datos_col1["45-49A"].replace("%", ""));
      //part[8] = Number(datosPorSeccion.value.datos_col1["50-54A"].replace("%", ""));
      //part[9] = Number(datosPorSeccion.value.datos_col1["55-59A"].replace("%", ""));
      //part[10] = Number(datosPorSeccion.value.datos_col1["60-64A"].replace("%", ""));
      //part[11] = Number(datosPorSeccion.value.datos_col1["65omas"].replace("%", ""));

      let valAbs = [];
      valAbs[0] = (part[0] / 100) * datosPorSeccion.value.datos_col4["18A"];
      valAbs[1] = (part[1] / 100) * datosPorSeccion.value.datos_col4["19A"];
      valAbs[2] = (part[2] / 100) * datosPorSeccion.value.datos_col4["20-24A"];
      valAbs[3] = (part[3] / 100) * datosPorSeccion.value.datos_col4["25-29A"];
      valAbs[4] = (part[4] / 100) * datosPorSeccion.value.datos_col4["30-34A"];
      valAbs[5] = (part[5] / 100) * datosPorSeccion.value.datos_col4["35-39A"];
      valAbs[6] = (part[6] / 100) * datosPorSeccion.value.datos_col4["40-44A"];
      valAbs[7] = (part[7] / 100) * datosPorSeccion.value.datos_col4["45-49A"];
      valAbs[8] = (part[8] / 100) * datosPorSeccion.value.datos_col4["50-54A"];
      valAbs[9] = (part[9] / 100) * datosPorSeccion.value.datos_col4["55-59A"];
      valAbs[10] = (part[10] / 100) * datosPorSeccion.value.datos_col4["60-64A"];
      valAbs[11] = (part[11] / 100) * datosPorSeccion.value.datos_col4["65omas"];
      //console.log(datosPorSeccion.value)
      let sumaAbs = valAbs.reduce((a, b) => a + b, 0);
      tablaNueva.value = [
        {
          nombre: "18 Años",
          peso: (valAbs[0] / sumaAbs) * 100,
          part: part[0],
          valorAbsoluto: valAbs[0],
        },
        {
          nombre: "19 Años",
          peso: (valAbs[1] / sumaAbs) * 100,
          part: part[1],
          valorAbsoluto: valAbs[1],
        },
        {
          nombre: "20-24 Años",
          peso: (valAbs[2] / sumaAbs) * 100,
          part: part[2],
          valorAbsoluto: valAbs[2],
        },
        {
          nombre: "25-29 Años",
          peso: (valAbs[3] / sumaAbs) * 100,
          part: part[3],
          valorAbsoluto: valAbs[3],
        },
        {
          nombre: "30-34 Años",
          peso: (valAbs[4] / sumaAbs) * 100,
          part: part[4],
          valorAbsoluto: valAbs[4],
        },
        {
          nombre: "35-39 Años",
          peso: (valAbs[5] / sumaAbs) * 100,
          part: part[5],
          valorAbsoluto: valAbs[5],
        },
        {
          nombre: "40-44 Años",
          peso: (valAbs[6] / sumaAbs) * 100,
          part: part[6],
          valorAbsoluto: valAbs[6],
        },
        {
          nombre: "45-49 Años",
          peso: (valAbs[7] / sumaAbs) * 100,
          part: part[7],
          valorAbsoluto: valAbs[7],
        },
        {
          nombre: "50-54 Años",
          peso: (valAbs[8] / sumaAbs) * 100,
          part: part[8],
          valorAbsoluto: valAbs[8],
        },
        {
          nombre: "55-59 Años",
          peso: (valAbs[9] / sumaAbs) * 100,
          part: part[9],
          valorAbsoluto: valAbs[9],
        },
        {
          nombre: "60-64 Años",
          peso: (valAbs[10] / sumaAbs) * 100,
          part: part[10],
          valorAbsoluto: valAbs[10],
        },
        {
          nombre: "65 o más",
          peso: (valAbs[11] / sumaAbs) * 100,
          part: part[11],
          valorAbsoluto: valAbs[11],
        },
      ];
      seriesLineal1.value = [
        {
          name: "PAN",
          data: [
            data.DF_2021 ? data.DF_2021.DF_PAN : 0,
            data.DL_2021 ? data.DL_2021.DL_PAN : 0,
            data.AY_2021 ? data.AY_2021.PAN_AYU : 0,
            data.PDTE_2018 ? data.PDTE_2018.PAN_PDTE : 0,
            data.DF_2018 ? data.DF_2018.PAN_DF : 0,
            data.DL_2018 ? data.DL_2018.PANDL : 0,
            data.AYU_2018 ? data.AYU_2018.PANAYU : 0,
            data.CEN_2018 ? data.CEN_2018.PAN_SEN : 0,
          ],
        },
        {
          name: "PRI",
          data: [
            data.DF_2021 ? data.DF_2021.DF_PRI : 0,
            data.DL_2021 ? data.DL_2021.DL_PRI : 0,
            data.AY_2021 ? data.AY_2021.PRI_AYU : 0,
            data.PDTE_2018 ? data.PDTE_2018.PRI_PDTE : 0,
            data.DF_2018 ? data.DF_2018.PRI_DF : 0,
            data.DL_2018 ? data.DL_2018.PRIDL : 0,
            data.AYU_2018 ? data.AYU_2018.PRIAYU : 0,
            data.CEN_2018 ? data.CEN_2018.PRI_SEN : 0,
          ],
        },
        {
          name: "PRD",
          data: [
            data.DF_2021 ? data.DF_2021.DF_PRD : 0,
            data.DL_2021 ? data.DL_2021.DL_PRD : 0,
            data.AY_2021 ? data.AY_2021.PRD_AYU : 0,
            data.PDTE_2018 ? data.PDTE_2018.PRD_PDTE : 0,
            data.DF_2018 ? data.DF_2018.PRD_DF : 0,
            data.DL_2018 ? data.DL_2018.PRDDL : 0,
            data.AYU_2018 ? data.AYU_2018.PRDAYU : 0,
            data.CEN_2018 ? data.CEN_2018.PRD_SEN : 0,
          ],
        },
        {
          name: "MORENA",
          data: [
            data.DF_2021 ? data.DF_2021.DF_MORENA : 0,
            data.DL_2021 ? data.DL_2021.DL_MORENA : 0,
            data.AY_2021 ? data.AY_2021.MORENA_CC : 0,
            data.PDTE_2018 ? data.PDTE_2018.MOREN_PDTE : 0,
            data.DF_2018 ? data.DF_2018.MORENA_DF : 0,
            data.DL_2018 ? data.DL_2018.MORENADL : 0,
            data.AYU_2018 ? data.AYU_2018.MORENAAYU : 0,
            data.CEN_2018 ? data.CEN_2018.MORENA_SEN : 0,
          ],
        },
      ];
      seriesBar1.value = [
        {
          name: "Hombres",
          data: [
            data.datos_col4.LISTA_18_HOMBRES,
            data.datos_col4.LISTA_19_HOMBRES,
            data.datos_col4.LISTA_20_24_HOMBRES,
            data.datos_col4.LISTA_25_29_HOMBRES,
            data.datos_col4.LISTA_30_34_HOMBRES,
            data.datos_col4.LISTA_35_39_HOMBRES,
            data.datos_col4.LISTA_40_44_HOMBRES,
            data.datos_col4.LISTA_45_49_HOMBRES,
            data.datos_col4.LISTA_50_54_HOMBRES,
            data.datos_col4.LISTA_55_59_HOMBRES,
            data.datos_col4.LISTA_60_64_HOMBRES,
            data.datos_col4.LISTA_65_Y_MAS_HOMBRES,
          ],
        },
        {
          name: "Mujeres",
          data: [
            data.datos_col4.LISTA_18_MUJERES,
            data.datos_col4.LISTA_19_MUJERES,
            data.datos_col4.LISTA_20_24_MUJERES,
            data.datos_col4.LISTA_25_29_MUJERES,
            data.datos_col4.LISTA_30_34_MUJERES,
            data.datos_col4.LISTA_35_39_MUJERES,
            data.datos_col4.LISTA_40_44_MUJERES,
            data.datos_col4.LISTA_45_49_MUJERES,
            data.datos_col4.LISTA_50_54_MUJERES,
            data.datos_col4.LISTA_55_59_MUJERES,
            data.datos_col4.LISTA_60_64_MUJERES,
            data.datos_col4.LISTA_65_Y_MAS_MUJERES,
          ],
        },
      ];
      let nombreEstados = Object.keys(data.datos_col2).map((e) =>
        e
          .replace("LN_", "")
          .replace(/_/g, " ")
          .toLowerCase()
          .split(" ")
          .map((ee) => ee.charAt(0).toUpperCase() + ee.slice(1))
          .join(" ")
      );
      nombreEstados[8] = "Ciudad de México";
      nombreEstados[14] = "Estado de México";
      let numeroEstados = Object.values(data.datos_col2);
      let mayor = Math.max(...numeroEstados);
      let indexMayor = numeroEstados.indexOf(mayor);
      filas.value = [
        ...nombreEstados.map((estado, i) => {
          return {
            estado: estado,
            participacion: numeroEstados[i],
            maximo: indexMayor === i ? true : false,
            porcentaje: (numeroEstados[i] * 100) / data.datos_col4["LISTA\nNOMINAL"],
          };
        }),
      ];
      //   seriesBar2.value = [
      //     {
      //       data: [
      //         data.estados.LISTA_AGUASCALIENTES,
      //         data.estados.LISTA_BAJA_CALIFORNIA,
      //         data.estados.LISTA_BAJA_CALIFORNIA_SUR,
      //         data.estados.LISTA_CAMPECHE,
      //         data.estados.LISTA_COAHUILA,
      //         data.estados.LISTA_COLIMA,
      //         data.estados.LISTA_CHIAPAS,
      //         data.estados.LISTA_CHIHUAHUA,
      //         data.estados.LISTA_CDMX,
      //         data.estados.LISTA_DURANGO,
      //         data.estados.LISTA_GUANAJUATO,
      //         data.estados.LISTA_GUERRERO,
      //         data.estados.LISTA_HIDALGO,
      //         data.estados.LISTA_JALISCO,
      //         data.estados.LISTA_ESTADO_DE_MEXICO,
      //         data.estados.LISTA_MICHOACAN,
      //         data.estados.LISTA_MORELOS,
      //         data.estados.LISTA_NAYARIT,
      //         data.estados.LISTA_NUEVO_LEON,
      //         data.estados.LISTA_OAXACA,
      //         data.estados.LISTA_PUEBLA,
      //         data.estados.LISTA_QUERETARO,
      //         data.estados.LISTA_QUINTANA_ROO,
      //         data.estados.LISTA_SAN_LUIS_POTOSI,
      //         data.estados.LISTA_SINALOA,
      //         data.estados.LISTA_SONORA,
      //         data.estados.LISTA_TABASCO,
      //         data.estados.LISTA_TAMAULIPAS,
      //         data.estados.LISTA_TLAXCALA,
      //         data.estados.LISTA_VERACRUZ,
      //         data.estados.LISTA_YUCATAN,
      //         data.estados.LISTA_ZACATECAS,
      //       ],
      //     },
      //   ];
    };
    const borrarDatos = () => {
      console.log("Borrados");
      datosPorSeccion.value = null;
    };
    const selectColor2 = (nombre) => {
      let nom = nombre.toUpperCase();
      switch (nom) {
        case "PRI":
        case "APT":
          return "#C62223";
        case "PAN":
        case "AC":
          return "#0965AC";
        case "PRD":
          return "#FDCA01";
        case "MC":
        case "MOVIMIENTO_CIUDADANO":
        case "C":
        case "CONVERGENCIA":
        case "CONV":
        case "CONVE":
        case "CD":
          return "#FD8204";
        case "MORENA":
        case "MOREN":
        case "MOR":
          return "#B2282F";
        case "PT":
          return "#E72B38";
        case "PVEM":
        case "PVEN":
          return "#8EBE28";
        case "NVA_ALIANZA":
        case "NA":
        case "NUEVA_ALIANZA":
          return "#00A4AC";
        default:
          return "#554B85";
      }
    };
    const verMapa = async (lugar, separador, tipo) => {
      //console.log("Ver mapa")
      if (mapa.getLayer("colores")) {
        //console.log("SWi eiste")
        mapa.removeLayer("colores");
        if (tipoEleccion.value === tipo) {
          tipoEleccion.value = null;
          return;
        }
      }

      let dire = produccion
        ? `/api/jal/estado/${lugar}/${separador}/12345/${encodeURIComponent(
            idUser.value
          )}`
        : `http://localhost:3000/api/jal/estado/${lugar}/${separador}/12345/${encodeURIComponent(
            idUser.value
          )}`;
      //console.log(dire,"Ajas")
      //const { data } = await axios.get(
      //  `http://localhost:3000/api/estado/${lugar}/${separador}/12345`
      //);
      const { data } = await axios.get(dire);
      //console.log(data)
      //console.log(data.ganadores)
      const info = data.ganadores
        .map((e) => e.split(","))
        .map((e) => (!isNaN(Number(e[0])) ? [Number(e[0]), selectColor2(e[1])] : null))
        .filter(Boolean)
        .flat();
      //console.log(info)
      tipoEleccion.value = tipo;
      //console.log(info,lugar, separador, tipo,data);
      //console.log(mapa.getLayer("actual"))
      if (mapa.getLayer("actual")) {
        mapa.removeLayer("actual");
      }

      mapa.addLayer({
        id: "colores",
        type: "fill",
        source: "states-source",
        "source-layer": "14_JALISCO-ch190o",
        paint: {
          "fill-color": ["match", ["get", "SECCION"], ...info, "#fff"],
          "fill-opacity": 0.7,
          "fill-outline-color": "#000",
        },
      });
    };
    const filterFn = (val, update) => {
      update(() => {
        const needle = val.toLowerCase();
        municipios.value = stringsMuni.filter(
          (v) => v.toLowerCase().indexOf(needle) > -1
        );
      });
    };
    const mostrarPdf = (e) => {
      const index = stringsMuni.findIndex((muni) => muni === e);
      //console.log(index);
      if (index !== 0) {
        pdf.value = "/descargas/" + index.toString() + ".pdf";
      } else {
        pdf.value = "/descargas/Info.pdf";
      }
    };
    return {
      tab,
      pdf,
      mostrarPdf,
      filterFn,
      selector,
      pagina,
      municipios,
      tipoEleccion,
      verMapa,
      tablaNueva,
      recuperarLocalizacion,
      logo,
      columns,
      filas,
      seccion,
      datosPorSeccion,
      buscarPorSeccion,
      borrarDatos,
      options: {
        chart: {
          id: "grafica-lineal1",
        },
        markers: {
          size: 4,
        },
        xaxis: {
          categories: [
            "DF2021",
            "DL2021",
            "AYU2021",
            "PDTE2018",
            "DF2018",
            "DL2018",
            "AYU2018",
            "SEN2018",
          ],
        },
      },
      options2: {
        chart: {
          id: "grafica-bar1",
          stacked: true,
        },
        dataLabels: {
          style: {
            colors: ["#FFF", "#FFF"],
          },
        },
        xaxis: {
          categories: [
            18,
            19,
            "20 a 24",
            "25 a 29",
            "30 a 34",
            "35 a 39",
            "40 a 44",
            "45 a 49",
            "50 a 54",
            "55 a 59",
            "60 a 64",
            "65 y más",
          ],
        },
      },
      options3: {
        chart: {
          id: "grafica-bar1",
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        xaxis: {
          categories: [
            "Aguascalientes",
            "Baja California",
            "Baja California Sur",
            "Campeche",
            "Coahuila",
            "Colima",
            "Chiapas",
            "Chihuahua",
            "Cdmx",
            "Durango",
            "Guanajuato",
            "Guerrero",
            "Hidalgo",
            "Jalisco",
            "Estado De Mexico",
            "Michoacan",
            "Morelos",
            "Nayarit",
            "Nuevo Leon",
            "Oaxaca",
            "Puebla",
            "Queretaro",
            "Quintana Roo",
            "San Luis Potosi",
            "Sinaloa",
            "Sonora",
            "Tabasco",
            "Tamaulipas",
            "Tlaxcala",
            "Veracruz",
            "Yucatan",
            "Zacatecas",
          ],
        },
      },
      seriesLineal1,
      seriesBar1,
      seriesBar2,
    };
  },
};
</script>
