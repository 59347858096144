<template>
    <tr>
    <td><q-btn :disable="datosFila==='datos_col3'" round color="secondary" icon="map" size="xs" @click="verMapa(datosFila)"/> {{ eleccion }}</td>
    <td
        :style="{
        backgroundColor:
            pan !== 'X' ? datosPorSeccion[datosGanador] !== 0 && datosPorSeccion[datosGanador] ===
            Number(datosPorSeccion[datosFila][pan])
            ? 'yellow'
            : null : null,
        }"
    >
        {{ pan !== 'X' ? datosPorSeccion[datosFila][pan] && datosPorSeccion[datosFila][pan] !== "NULL" && datosPorSeccion[datosFila][pan] !== '#¡VALOR!' ? datosPorSeccion[datosFila][pan] : '---' : '---'}}
    </td>
    <td
        :style="{
        backgroundColor:
            pri !== 'X' ? datosPorSeccion[datosGanador] !== 0 && datosPorSeccion[datosGanador] ===
            Number(datosPorSeccion[datosFila][pri])
            ? 'yellow'
            : null : null,
        }"
    >
        {{ pri !== 'X' ? datosPorSeccion[datosFila][pri] && datosPorSeccion[datosFila][pri] !== "NULL" && datosPorSeccion[datosFila][pri] !== '#¡VALOR!' ? datosPorSeccion[datosFila][pri] : '---' : '---'}}
    </td>
    <td
        :style="{
        backgroundColor:
            prd !== 'X' ? datosPorSeccion[datosGanador] !== 0 && datosPorSeccion[datosGanador] ===
            Number(datosPorSeccion[datosFila][prd])
            ? 'yellow'
            : null : null,
        }"
    >
        {{ prd !== 'X' ? datosPorSeccion[datosFila][prd] && datosPorSeccion[datosFila][prd] !== "NULL" && datosPorSeccion[datosFila][prd] !== '#¡VALOR!' ? datosPorSeccion[datosFila][prd] : '---' : '---'}}
    </td>
    <td
        :style="{
        backgroundColor:
            pt !== 'X' ? datosPorSeccion[datosGanador] !== 0 && datosPorSeccion[datosGanador] ===
            Number(datosPorSeccion[datosFila][pt])
            ? 'yellow'
            : null : null,
        }"
    >
        {{ pt !== 'X' ? datosPorSeccion[datosFila][pt] && datosPorSeccion[datosFila][pt] !== "NULL" && datosPorSeccion[datosFila][pt] !== '#¡VALOR!' ? datosPorSeccion[datosFila][pt] : '---' : '---'}}
    </td>
    <td
        :style="{
        backgroundColor:
            pvem !== 'X' ? datosPorSeccion[datosGanador] !== 0 && datosPorSeccion[datosGanador] ===
            Number(datosPorSeccion[datosFila][pvem])
            ? 'yellow'
            : null : null,
        }"
    >
        {{ pvem !== 'X' ? datosPorSeccion[datosFila][pvem] && datosPorSeccion[datosFila][pvem] !== "NULL" && datosPorSeccion[datosFila][pvem] !== '#¡VALOR!' ? datosPorSeccion[datosFila][pvem] : '---' : '---'}}
    </td>
    <td
        :style="{
        backgroundColor:
            mc !== 'X' ? datosPorSeccion[datosGanador] !== 0 && datosPorSeccion[datosGanador] ===
            Number(datosPorSeccion[datosFila][mc])
            ? 'yellow'
            : null : null,
        }"
    >
        {{ mc !== 'X' ? datosPorSeccion[datosFila][mc] && datosPorSeccion[datosFila][mc] !== "NULL" && datosPorSeccion[datosFila][mc] !== '#¡VALOR!' ? datosPorSeccion[datosFila][mc] : '---' : '---'}}
    </td>
    <td
        :style="{
        backgroundColor:
            (morena !== 'X' && datosGanador !== 'X') ? 
                datosPorSeccion[datosGanador] !== 0 && datosPorSeccion[datosGanador] === Number(datosPorSeccion[datosFila][morena]) ? 
                    'yellow'
                : null 
            : 
                datosGanador === 'X' ? 
                    'yellow' 
                : 
                    null,
        }"
    >
        {{ morena !== 'X' ? datosPorSeccion[datosFila][morena] && datosPorSeccion[datosFila][morena] !== "NULL" && datosPorSeccion[datosFila][morena] !== '#¡VALOR!' ? datosPorSeccion[datosFila][morena] : '---' : '---'}}
    </td>
    <td
        :style="{
        backgroundColor:
            na !== 'X' ? datosPorSeccion[datosGanador] !== 0 && datosPorSeccion[datosGanador] === 
            Number(datosPorSeccion[datosFila][na])
            ? 'yellow'
            : null : null,
        }"
    >
        {{ na !== 'X' ? datosPorSeccion[datosFila][na] && datosPorSeccion[datosFila][na] !== "NULL" && datosPorSeccion[datosFila][na] !== '#¡VALOR!' ? datosPorSeccion[datosFila][na] : '---' : '---'}}
    </td>
    <td>{{ otros !== 'X' ? datosPorSeccion[datosFila][otros] && datosPorSeccion[datosFila][otros] !== "NULL" && datosPorSeccion[datosFila][otros] !== '#¡VALOR!' ? datosPorSeccion[datosFila][otros] : '---' : '---'}}</td>
    <td>
        {{ canNoReg !== 'X' ? datosPorSeccion[datosFila][canNoReg] && datosPorSeccion[datosFila][canNoReg] !== "NULL" && datosPorSeccion[datosFila][canNoReg] !== '#¡VALOR!' ? datosPorSeccion[datosFila][canNoReg] : '---' : '---'}}
    </td>
    <td>{{ vNulo !== 'X' ? datosPorSeccion[datosFila][vNulo] && datosPorSeccion[datosFila][vNulo] !== "NULL" && datosPorSeccion[datosFila][vNulo] !== '#¡VALOR!' ? datosPorSeccion[datosFila][vNulo] : '---' : '---'}}</td>
    <td>{{ totales !== 'X' ? datosPorSeccion[datosFila][totales] && datosPorSeccion[datosFila][totales] !== "NULL" && datosPorSeccion[datosFila][totales] !== '#¡VALOR!' ? datosPorSeccion[datosFila][totales] : '---' : '---'}}</td>
    <td>{{ listaNominal !== 'X' ? datosPorSeccion[datosFila][listaNominal] && datosPorSeccion[datosFila][listaNominal] !== "NULL" && datosPorSeccion[datosFila][listaNominal] !== '#¡VALOR!' ? datosPorSeccion[datosFila][listaNominal] : '---' : '---'}}</td>
    <td>{{ part !== 'X' ? datosPorSeccion[datosFila][part] && datosPorSeccion[datosFila][part] !== "NULL" && datosPorSeccion[datosFila][part] !== '#¡VALOR!' ? typeof datosPorSeccion[datosFila][part] === 'number' ? (datosPorSeccion[datosFila][part] * (partPorcentaje ? partPorcentaje : 1) ).toFixed(2) + '%' : datosPorSeccion[datosFila][part] : '---' : '---'}}</td>
    </tr>
</template>
<script>
    export default {
        emits: ['localizar'],
        props:{
            eleccion: String,
            datosPorSeccion: Object,
            datosFila: String,
            datosGanador: String,
            pan: String,
            pri: String,
            prd: String,
            pt: String,
            pvem: String,
            mc: String,
            morena: String,
            na: String,
            otros: String,
            canNoReg: String,
            vNulo: String,
            totales: String,
            listaNominal: String,
            part: String,
            partPorcentaje: String,
        },
        setup(props,{emit}){
            const verMapa = (e) => {
                emit('localizar', e)
            }
            return {
                verMapa
            }
        }
    }
</script>
