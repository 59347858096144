<template>
    <thead style="position: sticky;top: 0;background-color: white;z-index: 1;">
        <tr>
        <th>Elección</th>
        <th>
            <q-avatar square
            ><img
                src="http://www.ieesonora.org.mx/_img/logos/tn_pan.png"
                alt="PAN"
            /></q-avatar>
        </th>
        <th>
            <q-avatar square
            ><img
                src="http://www.ieesonora.org.mx/_img/logos/tn_pri.png"
                alt="PRI"
            /></q-avatar>
        </th>
        <th>
            <q-avatar square
            ><img
                src="http://www.ieesonora.org.mx/_img/logos/tn_prd.png"
                alt="PRD"
            /></q-avatar>
        </th>
        <th>
            <q-avatar square
            ><img
                src="http://www.ieesonora.org.mx/_img/logos/tn_pt.png"
                alt="PT"
            /></q-avatar>
        </th>
        <th>
            <q-avatar square
            ><img
                src="http://www.ieesonora.org.mx/_img/logos/tn_pvem.png"
                alt="PVEM"
            /></q-avatar>
        </th>
        <th>
            <q-avatar square
            ><img
                src="http://www.ieesonora.org.mx/_img/logos/tn_mc.png"
                alt="MC"
            /></q-avatar>
        </th>
        <th>
            <q-avatar square
            ><img
                src="http://www.ieesonora.org.mx/_img/logos/tn_morena.png"
                alt="MORENA"
            /></q-avatar>
        </th>
        <th>
            <q-avatar square
            ><img :src="icon_naem" alt="NAEM"
            /></q-avatar>
        </th>
        <th>Otros</th>
        <th>No registrados</th>
        <th>Nulos</th>
        <th>Total</th>
        <th>Lista nominal</th>
        <th>Participación</th>
        </tr>
    </thead>
</template>
<script>

export default {
    name: "CabeceraComponent",
    setup() {
        const icon_naem = require("../assets/tn_naem.png");
        return {
            icon_naem
        }
    },
}
</script>
