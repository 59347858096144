import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import VueApexCharts from "vue3-apexcharts";
import VueRecaptcha from "vue3-recaptcha-v2";
createApp(App).use(Quasar, quasarUserOptions).use(VueRecaptcha,{
    siteKey:"6LduhlglAAAAAM44ttUQGZaiG8hVhPdUh5b7c6op",
    alterDomain: false
}).use(VueApexCharts).mount('#app')
