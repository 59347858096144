<template>
  <q-layout>
    <div v-if="!logged" class="row justify-center">
      <q-toolbar style="background-color: #554b85">
        <q-avatar rounded size="80px" style="margin-bottom: 10px">
          <img :src="logo" />
        </q-avatar>
        <q-space />
      </q-toolbar>
      <q-page-container>

      
      <q-page
        style="height: 80vh; min-height: 80vh"
        class="bg-wite window-width row justify-center items-center"
      >
        <div class="column">
          <div class="row">
            <div class="text-h5" style="color: #554b85">Iniciar sesión</div>
          </div>
          <div class="row">
            <q-card
              square
              bordered
              class="shadow-1"
              style="background-color: white; width: 360px"
            >
              <q-card-section>
                <q-form class="q-gutter-md" @submit="enviarLogin">
                  <q-input
                    square
                    color="#554b85"
                    label-color="#554b85"
                    clearable
                    input-style="color:#554b85;"
                    v-model="user"
                    type="text"
                    label="Usuario"
                    lazy-rules
                    :rules="[(val) => (val && val.length > 0) || 'Ingresa un usuario']"
                  />
                  <q-input
                    v-model="password"
                    :type="isPwd ? 'password' : 'text'"
                    square
                    color="#554b85"
                    label-color="#554b85"
                    input-style="color:#554b85;"
                    label="Contraseña"
                    lazy-rules
                    :rules="[
                      (val) => (val && val.length > 0) || 'Ingresa una contraseña',
                    ]"
                  >
                    <template v-slot:append>
                      <q-icon
                        :name="isPwd ? 'visibility_off' : 'visibility'"
                        class="cursor-pointer"
                        @click="isPwd = !isPwd"
                      />
                    </template>
                  </q-input>

                  <q-card-actions class="q-px-md">
                    <q-btn
                      unelevated
                      style="background-color: #554b85; color: white"
                      size="lg"
                      class="full-width"
                      label="Login"
                      type="submit"
                    />
                  </q-card-actions>
                </q-form>
              </q-card-section>
              <q-card-section
                class="text-center q-pa-none"
                style="text-align: -webkit-center"
              >
                <vue-recaptcha
                  theme="light"
                  size="normal"
                  :tabindex="0"
                  @widgetId="recaptchaWidget = $event"
                  @verify="callbackVerify($event)"
                  @expired="callbackExpired()"
                  @fail="callbackFail()"
                />
                <q-btn @click="actionReset" style="width: 80%" class="q-my-md"
                  >Reset</q-btn
                >
              </q-card-section>
            </q-card>
          </div>
        </div>
      </q-page>
    </q-page-container>
    </div>
    <div v-if="logged" class="row justify-center">
      <edomex v-if="estadoNombre==='edomex'" :data="info"/>
      <jalisco v-if="estadoNombre==='jalisco'" :data="info" />
      <chiapas v-if="estadoNombre==='chiapas'" :data="info"/>
    </div>
    <div class="q-mt-md" style="background-color: #554b85; padding: 10px; color: white">
      -Electopedia 2024-
    </div>
  </q-layout>
</template>

<script>
import { ref } from "vue";
import { useQuasar } from "quasar";

import axios from "axios";
import { VueRecaptcha, useRecaptcha } from "vue3-recaptcha-v2";
import Edomex from "./components/edomex.vue"
import Jalisco from "./components/jalisco.vue"
import Chiapas from './components/chiapas.vue'
export default {
  name: "App",
  components: {
    VueRecaptcha,
    Edomex,
    Jalisco,
    Chiapas
  },
  setup() {
    const $q = useQuasar();
    const logo = require("./assets/logoElectopedia.svg");
    const { resetRecaptcha } = useRecaptcha();
    const isPwd = ref(true);
    const user = ref("");
    const password = ref("");

    const logged = ref(false);
    const verificado = ref(false);
    const estadoNombre = ref('')
    const produccion = true;

    if(!produccion){
      user.value = ""
      password.value = ""
    }
    //recaptcha
    const recaptchaWidget = ref(null);
    const callbackVerify = () => {
      verificado.value = true;
      //console.log(response)
    };
    const info = ref({})
    const callbackExpired = () => {
      $q.notify({
        color: "info",
        message: "Fallo de autentificación",
        position: "top",
      });
    };
    const callbackFail = () => {
      $q.notify({
        color: "info",
        message: "Fallo de autentificación",
        position: "top",
      });
    };
    // Reset Recaptcha action
    const actionReset = () => {
      resetRecaptcha(recaptchaWidget.value);
    };
    const enviarLogin = async () => {
      if (!verificado.value && produccion) { //Cambiar a negado para pruebas
       $q.notify({
         color: "info",
         message: "Autentificate con el reCaptcha",
         position: "top",
       });
       return
      }
      let direccion = produccion
        ? `/api/log/${user.value}/${encodeURIComponent(password.value)}/12345`
        : `http://localhost:3000/api/log/${user.value}/${encodeURIComponent(
            password.value
          )}/12345`;
      const { data } = await axios.get(direccion);
      //console.log(data)
      if (!data.log) {
        $q.notify({
          color: "negative",
          message: "Usuario o contraseña incorrecta",
          position: "top",
        });
        resetRecaptcha(recaptchaWidget.value);
        return;
      }
      // console.log(data)
      info.value = {
        idUser:data.id,
        secciones:data.secciones
      }
      logged.value = true;
      estadoNombre.value = data.estado;
      // setTimeout(() => {
      //   activarMapa();
      // }, 1000);
    };







    return {
      logo,
      estadoNombre,
      recaptchaWidget,
      callbackVerify,
      callbackFail,
      callbackExpired,
      actionReset,
      isPwd,
      password,
      user,
      enviarLogin,
      logged,
      info
    };
  },
};
</script>
<style lang="sass">

.pdf-app .toolbar
  position: static !important
body
  color: black
.apexcharts-tooltip
  background: red
  color: black
#map
  height: 400px
.my-sticky-header-table
  /* height or max-height is important */
  height: 310px

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th
    /* bg color is important for th; just specify one */
    background-color: #554B85
    color: #fff
    font-weight: bold

  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0

  /* this is when the loading indicator appears */
  &.q-table--loading thead tr:last-child th
    /* height of all previous header rows */
    top: 48px
</style>
